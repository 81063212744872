define('ember-array-helper/helpers/array', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var helper = Ember.Helper.helper;
  var emberA = Ember.A;
  exports.default = helper(function array(params) {
    // params is a frozen, non-ember array
    return emberA(params.slice());
  });
});