/**
 * FileTransport jQuery plugin.
 */
(function ($) {
	"use strict";

	$.fn.fileTransport = function (params) {
		$(this).fileupload({
			dataType: "json",
			url: '//api-gs1.intellex.rs/upload/put',
			// url: "//api-gs1/upload/put",
			// url: '//gs1.erp.adam.office.intellex.rs:8242/upload/put',
			element: this,

			/**
			 * Invoked when file is added to the queue.
			 *
			 * @param {object} event The event that created this action.
			 * @param {object} data The data containing additional details.
			 */
			add: function (event, data) {

				// Set the file ID
				$(this).data("upload-progress-file", data.files[0].name);

				params.start && params.start(event, data.files[0]);
				data.submit();
			},

			/**
			 * Set additional data sent during file upload.
			 *
			 * @param {object} form The form which is being submitted.
			 *
			 * @returns {Array} The additional data to send.
			 */
			formData: function (form) {
				return params.data ? params.data(form) : [];
			},

			/**
			 * Invoked when progress is received
			 *
			 * @param {object} event The event that created this action.
			 * @param {object} data The data containing progress details.
			 */
			progress: function (event, data) {
				params.progress && params.progress(event, data.files[0], parseInt(data.loaded / data.total * 100, 10), data);
			},

			/**
			 * Invoked when file is successfully uploaded.
			 *
			 * @param {object} event The event that created this action.
			 * @param {object} data The data containing additional details.
			 */
			done: function (event, data) {
				if (data.result.success) {
					params.success && params.success(event, data.files[0], data.result.token, data.result.meta, data);
				} else {
					params.error && params.error(event, data.files[0].name, data.result.error);
				}
			},

			/**
			 * Invoked when an error occurs.
			 *
			 * @param {object} event The event that created this action.
			 * @param {string} error The type of error.
			 * @param {string} errorMessage The error message.
			 */
			error: function (event, error, errorMessage) {

				// Get the id and clear the identity
				var id = $(this.element[0]).data("upload-progress-file");
				$(this.element[0]).data("upload-progress-file", null);

				params.error && params.error(event, id, errorMessage);
			}

		});
	};
})(jQuery, window, document);
